<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="min-w-[280px] max-w-[280px] md:min-w-[350px] md:max-w-[350px] mb-4 lg:mb-10 flex flex-col"
  >
    <div
      class="flex flex-col rounded-t-lg md:min-h-[200px]"
      :class="`bg-${props.colorHeaderBg}`"
    >
      <div v-if="props.price">
        <div class="flex items-end justify-center w-full mt-4 md:pt-8">
          <div
            class="text-bold-42 md:text-bold-72"
            :class="`!text-${props.colorHeaderTxt}`"
          >
            {{ props.price }}
          </div>
          <div
            class="text-bold-22 md:text-bold-34 flex flex-col"
            :class="`!text-${props.colorHeaderTxt}`"
          >
            <span>{{ `/${props.priceInterval}` }}</span>
            <span
              class="ml-2 lg:ml-4 text-regular-14 -mt-2"
              :class="`!text-${props.colorHeaderTxt}`"
              >{{ t('taxNotice') }}</span
            >
          </div>
        </div>
        <div
          class="text-center text-bold-10 md:text-bold-14"
          :class="`!text-${props.colorHeaderTxt}`"
        >
          {{ props.priceCondition }}
        </div>
        <div
          class="mt-2 text-center md:mt-6 text-bold-16 md:text-bold-24"
          :class="`!text-${props.colorHeaderTxt}`"
        >
          {{ props.tier }}
        </div>
      </div>

      <div v-if="props.priceText" class="w-full text-center pt-3.5 md:pt-8">
        <div
          class="text-bold-22 md:text-bold-34"
          :class="`!text-${props.colorHeaderTxt}`"
        >
          {{ props.priceText }}
        </div>
        <div
          class="-mt-2 text-bold-54 md:text-bold-72"
          :class="`!text-${props.colorHeaderTxt}`"
        >
          {{ props.priceSubText }}
        </div>

        <div
          class="mt-2 text-center md:mt-6 text-bold-16 md:text-bold-24"
          :class="`!text-${props.colorHeaderTxt}`"
        >
          {{ props.tier }}
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-center min-h-[256px] max-h-[300px] md:min-h-[394px] md:max-h-[394px] pt-4 md:pt-12 bg-white rounded-b-lg"
      :class="{ 'lg:pb-[40px]': props.featureText }"
    >
      <ul v-if="props.features">
        <li
          v-for="feat in props.features"
          :key="feat.label"
          class="mt-1 lg:mt-2"
        >
          <Icon
            :name="`${
              feat.isIncluded ? 'heroicons:check' : 'heroicons:x-mark'
            }`"
            :class="`${
              feat.isIncluded ? 'text-iq-red' : 'text-iq-grey-inactive'
            }`"
            class="w-4 h-4"
          />
          <span class="text-regular-14 md:text-regular-20 !text-iq-black ml-4">
            {{ feat.label }}
          </span>
        </li>
      </ul>

      <div v-if="props.featureText" class="px-8">
        <div
          class="text-regular-14 md:text-regular-20 !text-iq-grey-text md:min-h-[170px] min-h-[136px]"
        >
          {{ props.featureText }}
        </div>
      </div>

      <UiButton
        v-if="!props.hideButton"
        class="w-4/5 mt-4 md:mt-12 !text-iq-white !tracking-[5px] iq-button-success text-medium-12 md:text-medium-20 hover:bg-iq-red max-h-10 min-h-10"
        :class="{ 'mb-10': props.featureText }"
        @click="emit('click:price-card:cta', textCta, tier)"
        >{{ props.textCta }}</UiButton
      >
      <div
        class="flex text-bold-10 md:text-bold-14 !text-iq-black my-2 md:my-4 md:!leading-7 text-center"
        :class="{ '!mt-25': props.hideButton }"
        v-html="props.priceInfo"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import type { TierLevel } from '~/enum/tier-level.enum';

// Types
type Feature = {
  label: string;
  isIncluded: boolean;
};

type BaseProps = {
  colorHeaderBg: string;
  colorHeaderTxt: string;
  hideButton?: boolean;
  textCta: string;
  tier: TierLevel;
};

type FeatureProps = {
  features?: Feature[];
  featureText?: string;
  priceInterval?: string;
};

type PriceProps = {
  price?: string;
  priceCondition?: string;
  priceInfo?: string;
  priceText?: string;
  priceSubText?: string;
};

type Props = BaseProps & FeatureProps & PriceProps;

// Component setup
const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'click:price-card:cta', value: string, tier: TierLevel): void;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "de": {
    "taxNotice": "* zzgl. MwSt"
  },
  "en": {
    "taxNotice": "* plus VAT"
  }
}
</i18n>
